

*{
  font-family: "BaiJamjuree";
 }
body{
  cursor: context-menu;
  min-height: 100vh;
 font-family: Roboto, "Helvetica Neue", sans-serif;
 overflow-y: auto !important;  
 overflow-x: hidden !important;
}

.no-scroll {
  overflow: hidden;
}
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";


/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: "BaiJamjuree";
    font-style: normal;
    font-weight: 200;
    src:local("BaiJamjuree"),url("./font/Baijamjuree/BaiJamjuree-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "BaiJamjuree";
    font-style: normal;
    font-weight: 300;
    src:local("BaiJamjuree"),url("./font/Baijamjuree/BaiJamjuree-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: 'BaiJamjuree';
    font-style: normal;
    font-weight: 400;
    src:local("BaiJamjuree"),url("./font/Baijamjuree/BaiJamjuree-Regular.ttf") format("truetype");
   }
  
@font-face {
    font-family: "BaiJamjuree";
    font-style: normal;
    font-weight: 500;
    src:local("BaiJamjuree"),url("./font/Baijamjuree/BaiJamjuree-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: 'BaiJamjuree';
    font-style: normal;
    font-weight: 600;
    src:local("BaiJamjuree"),url("./font/Baijamjuree/BaiJamjuree-SemiBold.ttf")format("truetype");
  }
  @font-face {
    font-family: 'BaiJamjuree';
    font-style: normal;
    font-weight: 700;
    src:local("BaiJamjuree"),url("./font/Baijamjuree/BaiJamjuree-Bold.ttf")  format("truetype");
  }

   /* Montserrat */
   @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 200;
    src:local("Montserrat"),url("./font/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
  }
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    src:local("Montserrat"),url("./font/Baijamjuree/BaiJamjuree-Regular.ttf") format("truetype");
  }
  
@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    src:local("Montserrat"),url("./font/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src:local("Montserrat"),url("./font/Montserrat/Montserrat-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src:local("Montserrat"),url("./font/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  }
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src:local("Montserrat"),url("./font/Montserrat/Montserrat-Medium.ttf") format("truetype");
   }
    /* poppins*/
    @font-face {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 200;
      src:local("Poppins"),url("./font/Poppins/Poppins-Light.ttf") format("truetype");
    }
    @font-face {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      src:local("Poppins"),url("./font/Poppins/Poppins-Regular.ttf") format("truetype");
    }
    
  @font-face {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      src:local("Poppins"),url("./font/Poppins/Poppins-SemiBold.ttf") format("truetype");
    }
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      src:local("Poppins"),url("./font/Poppins/Poppins-Bold.ttf") format("truetype");
    }
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      src:local("Poppins"),url("./font/Poppins/Poppins-Thin.ttf") format("truetype");
    }
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      src:local("Poppins"),url("./font/Poppins/Poppins-Black.ttf") format("truetype");
     }
     @font-face {
      font-family: "Arimo";
      font-style: normal;
      font-weight: 700;
      src:local("Arimo"),url("./font/Arimo/static/Arimo-Bold.ttf") format("truetype");
    }
    




